.tagline {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 900px) {
    .tagline {
      max-width: calc(100vw - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%; } }

.hero {
  position: relative;
  padding-top: 42.65%;
  overflow: hidden;
  max-width: 100vw; }

.letterBox {
  position: absolute;
  height: 0;
  top: -60px;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%; }

.tagline {
  position: absolute;
  bottom: 20%;
  left: 0;
  font-size: 3rem;
  font-weight: 600;
  color: white;
  z-index: 100;
  padding: 1.875rem;
  max-width: 50rem;
  margin-left: 1.875rem;
  background-size: 0 0;
  display: inline-block; }
  .tagline span {
    padding: 0;
    visibility: hidden;
    position: relative; }
    .tagline span:after {
      animation: changeAction 10s linear infinite alternate;
      content: 'adventures';
      font-weight: 300;
      border-bottom: 4px solid #ff3333;
      position: absolute;
      top: 0;
      left: 1rem;
      visibility: visible; }

@keyframes changeAction {
  0% {
    content: 'hikes'; }
  20% {
    content: 'rides'; }
  40% {
    content: 'trips'; }
  60% {
    content: 'flights'; }
  80% {
    content: 'journeys'; }
  100% {
    content: 'adventures'; } }

.hero iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  pointer-events: none; }

/* Mobile styling */
@media screen and (max-width: 900px) {
  .hero iframe {
    display: none; }
  .hero {
    padding: 0;
    height: auto;
    min-height: 12rem;
    position: relative;
    background-size: cover;
    display: flex;
    align-items: flex-end; }
  .tagline {
    position: relative;
    width: 100vw;
    bottom: auto;
    font-size: 2rem;
    margin: 0; } }
