body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: "Poppins", "sans-serif";
  font-size: 0.875rem; }
  body :global(#root) {
    height: 100%; }
    body :global(#root) > div {
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr auto; }

textarea {
  font-family: 'Poppins', sans-serif;
  box-shadow: none;
  outline: none; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }
