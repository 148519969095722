.tagline, .sceneGroup, .howItWorks {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 900px) {
    .tagline, .sceneGroup, .howItWorks {
      max-width: calc(100vw - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%; } }

.tagline, .sceneGroup, .howItWorks {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 900px) {
    .tagline, .sceneGroup, .howItWorks {
      max-width: calc(100vw - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%; } }

.uploadTrack, .stravaButton {
  height: 3rem;
  background-color: #e5e5e5;
  font-size: 0.875rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  color: #ff3333;
  cursor: pointer;
  font-weight: 700; }
  .uploadTrack:hover, .stravaButton:hover {
    filter: brightness(125%); }
  .uploadTrack:active, .stravaButton:active {
    filter: brightness(85%); }

.uploadTrack, .stravaButton {
  height: 2rem;
  padding: 0.25rem 1rem; }

input {
  font-size: calc(0.875rem + 1px);
  color: #21203c; }
  input:focus {
    outline: none; }
  input:focus::placeholder {
    opacity: 0; }

@keyframes looking {
  0% {
    margin-left: 10%; }
  50% {
    margin-left: 25%; }
  100% {
    margin-left: 10%; } }

@keyframes pulse {
  0% {
    background: linear-gradient(to right, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a, #76708a, #76708a, #76708a, #76708a); }
  10% {
    background: linear-gradient(to right, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a, #76708a, #76708a, #76708a); }
  20% {
    background: linear-gradient(to right, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a, #76708a, #76708a); }
  30% {
    background: linear-gradient(to right, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a, #76708a); }
  40% {
    background: linear-gradient(to right, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a); }
  50% {
    background: linear-gradient(to right, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333, #76708a); }
  60% {
    background: linear-gradient(to right, #76708a, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333, #ff3333); }
  70% {
    background: linear-gradient(to right, #ff3333, #76708a, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333, #ff3333); }
  80% {
    background: linear-gradient(to right, #ff3333, #ff3333, #76708a, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333, #ff3333); }
  90% {
    background: linear-gradient(to right, #ff3333, #ff3333, #ff3333, #76708a, #76708a, #76708a, #76708a, #76708a, #ff3333, #ff3333); }
  100% {
    background: linear-gradient(to right, #ff3333, #ff3333, #ff3333, #ff3333, #76708a, #76708a, #76708a, #76708a, #76708a, #ff3333); } }

.hero {
  position: relative;
  padding-top: 42.65%;
  overflow: hidden;
  max-width: 100vw; }

.letterBox {
  position: absolute;
  height: 0;
  top: -60px;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%; }

.tagline {
  position: absolute;
  bottom: 20%;
  left: 0;
  font-size: 3rem;
  font-weight: 600;
  color: white;
  z-index: 100;
  padding: 1.875rem;
  max-width: 50rem;
  margin-left: 1.875rem;
  background-size: 0 0;
  display: inline-block; }
  .tagline span {
    padding: 0;
    visibility: hidden;
    position: relative; }
    .tagline span:after {
      animation: changeAction 10s linear infinite alternate;
      content: 'adventures';
      font-weight: 300;
      border-bottom: 4px solid #ff3333;
      position: absolute;
      top: 0;
      left: 1rem;
      visibility: visible; }

@keyframes changeAction {
  0% {
    content: 'hikes'; }
  20% {
    content: 'rides'; }
  40% {
    content: 'trips'; }
  60% {
    content: 'flights'; }
  80% {
    content: 'journeys'; }
  100% {
    content: 'adventures'; } }

.hero iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  pointer-events: none;
  object-fit: cover; }

.sceneGroup {
  margin-top: calc(0.5rem * 8);
  justify-content: center;
  grid-auto-flow: inherit; }

h3 {
  font-size: 0.875rem;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  margin-bottom: calc(0.5rem * 2); }

h1 {
  font-size: 3rem;
  color: #21203c; }

.howItWorks {
  padding: 8rem 0;
  text-align: center;
  max-width: 56rem;
  align-items: center; }

.steps {
  margin-top: 4.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: space-around; }

.uploadTrack {
  background-color: #ff3333;
  color: white;
  width: 12rem;
  margin: calc(1.875rem / 2); }

.heroStravaButton {
  color: white;
  position: absolute;
  bottom: 2rem;
  right: 2rem; }

.mobileStravaButton {
  display: none; }

.stravaButton {
  background-color: #fc4c02;
  filter: brightness(100%);
  color: white;
  width: 12rem;
  margin: calc(1.875rem / 2); }
  .stravaButton img {
    filter: brightness(0) invert(1);
    height: 1rem;
    margin-left: 0.5rem; }

/* Mobile styling */
@media screen and (max-width: 900px) {
  .hero iframe {
    display: none; }
  .hero {
    padding: 0;
    height: auto;
    min-height: 12rem;
    position: relative;
    background-size: cover;
    display: flex;
    align-items: flex-end; }
  .tagline {
    position: relative;
    width: 100vw;
    bottom: auto;
    font-size: 2rem;
    margin: 0; }
  .sceneGroup h3 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    width: 22rem;
    margin-left: auto;
    margin-right: auto; }
  .howItWorks {
    max-width: calc(90% - 1.875rem);
    padding: calc(1.875rem / 2); }
    .howItWorks h1 {
      font-size: 1.25rem; }
    .howItWorks .steps {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr; }
  .heroStravaButton {
    display: none; }
  .mobileStravaButton {
    display: flex;
    justify-content: center;
    color: white;
    margin-bottom: -2rem; } }
